import { render, staticRenderFns } from "./UCIrvineChem1LC_FS4_Safety1.vue?vue&type=template&id=0249d007&scoped=true&"
import script from "./UCIrvineChem1LC_FS4_Safety1.vue?vue&type=script&lang=ts&"
export * from "./UCIrvineChem1LC_FS4_Safety1.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0249d007",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VContainer,VForm,VRow,VTextarea})
